import * as React from 'react';
import { getApiRankerUrl } from 'helpers/urls';
import { type ReportSet, getReportType } from 'models/report';
import { type RankerApi } from 'pages/api/ranker';
import { type ReportPeriod } from 'report/reportPeriod';

export type RankerJsonLoaderResult<Ranker extends RankerApi> =
    | {
          type: 'loading';
      }
    | {
          type: 'notFound';
      }
    | { type: 'error' }
    | { type: 'success'; report: Ranker };

export const useRankerJsonLoader = <Ranker extends RankerApi>({
    reportSet,
    period,
    accessKey,
}: {
    reportSet: ReportSet;
    period: ReportPeriod;
    accessKey: string | null;
}): RankerJsonLoaderResult<Ranker> => {
    const [result, setResult] = React.useState<RankerJsonLoaderResult<Ranker>>({ type: 'loading' });

    const reportType = React.useMemo(() => {
        return getReportType(reportSet);
    }, [reportSet]);

    React.useEffect(() => {
        let hasUnmounted = false;
        let loadDataAttempts = 0;

        const loadData = async () => {
            // use a function to workaround TypeScript bug where it doesn't know that `hasUnmounted` can be overwritten in an async function
            // https://www.typescriptlang.org/play/?ts=4.6.4&ssl=1&ssc=5&pln=1&pc=13#code/DYUwLgBA7gFghmEA3EAnCBeCZUFcQDcAUEQGa4B2AxmAJYD2F2IAzmABQCUEA3kRAIi1SEdgEJYCZGm59B8iKnC5UFfoIC+JeQHod0eIhSoMOfBDgsIIAB4AHEDRAATdQKqMW9UADpg9AHN2SSMZYi0iSwBPaghyajpGZjYAJi5eNyERcRDpVFlM+SUwFTV5CPk4KDhaSAoQKAgABVR6AFtaFhAAHiR6WmcAPnYlL2AUTEGMhUEusAAVWjaQelwOdIwpuRmFXONMOLhgLuJCndHvFC5iHYgNABoIAEYABjfOTI1OU919PbRTHgQJk9NgYJ0hFYKPRIKhaAEYGBMh4KGMQH5AsFDHlvkQIog2FwiASwGlOEA
            const isUnmounted = () => hasUnmounted;

            if (isUnmounted()) {
                return;
            }

            loadDataAttempts++;

            const url = getApiRankerUrl({
                regionId: reportSet.regionId,
                slug: reportSet.slug,
                year: period.year.toString(),
                month: period.month.toString(),
                accessKey: accessKey ?? undefined,
            });

            setResult({ type: 'loading' });

            let report;

            try {
                const response = await fetch(url);

                if (isUnmounted()) {
                    return;
                }

                if (response.status === 404) {
                    setResult({ type: 'notFound' });

                    return;
                }

                if (response.status !== 200) {
                    throw Error();
                }

                report = (await response.json()) as Ranker;
            } catch {
                if (isUnmounted()) {
                    return;
                }

                if (loadDataAttempts < 3) {
                    void loadData();

                    return;
                }

                setResult({ type: 'error' });

                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (hasUnmounted) {
                return;
            }

            // sanity check the API is giving us the right report
            if (report.type !== reportType) {
                throw Error('useRankerJsonLoader returning wrong type');
            }

            setResult({
                type: 'success',
                report,
            });
        };

        void loadData();

        return () => {
            hasUnmounted = true;
        };
    }, [reportType, reportSet.regionId, reportSet.slug, period.year, period.month, accessKey]);

    return result;
};
