import { alpha, Stack, Typography, Box, Container, Grid } from '@mui/material';
import { type GetServerSideProps, type NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { NavLinks } from 'components/Header';
import { useLocale } from 'components/LocalizationProvider';
import { ReportDisclaimer } from 'components/ReportDisclaimer';
import {
    type ReportTableProps,
    ReportTableNetworkAu,
    ReportTableNetworkCa,
    ReportTableNetworkNl,
    ReportTableNetworkNz,
    ReportTableNetworkUsDownloads,
    ReportTableNetworkUsUsers,
    ReportTablePodcastAu,
    ReportTablePodcastLatam,
    ReportTablePodcastNl,
    ReportTablePodcastNz,
    ReportTablePodcastUs,
    ReportTableSalesRepresentativeAu,
    ReportTableNetworkLatam,
    ReportTableDemosPlusPodcastAu,
} from 'components/ReportTable';
import { TritonFooter } from 'components/TritonFooter';
import { TritonLogo } from 'components/TritonLogo';
import { getDateStringFromISO8601String } from 'helpers/date';
import { fn } from 'helpers/fn';
import { getFirstQueryParam } from 'helpers/getFirstQueryParam';
import { addCacheControlHeader, cacheDurationSeconds } from 'helpers/headers';
import { getRankerUrl } from 'helpers/urls';
import {
    type ReportSet,
    getRegionId,
    getReportSetBySlug,
    getDefaultReportSet,
    regionTitles,
    getReportSetTitle,
    getReportType,
    getReportSetsByRegionId,
} from 'models/report';
import { siteTitle } from 'pages';
import { prisma } from 'prismaClient';
import {
    type ReportPeriod,
    getReportPeriodFromDate,
    formatReportPeriodLabel,
    getDateUtcFromReportPeriod,
    getReportPeriodFromStrings,
    reportPeriodsAreEqual,
} from 'report/reportPeriod';
import { theme } from 'styles/mui-theme';
import { TritonDivider } from 'styles/styles';

type PageProps = {
    accessKey: string | null;
    regionReportSlugsToShow: string[];
    regionReportSlugsInCurrentPeriod: string[];
    reportSet: ReportSet;
    period: ReportPeriod;
    periodStartDateString: string;
    periodEndDateString: string;
    reportPeriods: ReportPeriod[];
};

const Ranker: NextPage<PageProps> = ({
    accessKey,
    regionReportSlugsToShow,
    regionReportSlugsInCurrentPeriod,
    reportSet,
    period,
    periodStartDateString,
    periodEndDateString,
    reportPeriods,
}) => {
    const locale = useLocale();
    const intl = useIntl();

    const regionTitle = regionTitles[reportSet.regionId];

    const reportSetTitle = React.useMemo(() => {
        return getReportSetTitle(reportSet);
    }, [reportSet]);

    const reportPeriod = React.useMemo(() => {
        return formatReportPeriodLabel(period, locale);
    }, [period, locale]);

    const reportType = React.useMemo(() => {
        return getReportType(reportSet);
    }, [reportSet]);

    // we only want to show reports that are available
    const reportSetLinks = React.useMemo(() => {
        const regionReportSlugsToShowSet = new Set(regionReportSlugsToShow);
        const regionReportSlugsInCurrentPeriodSet = new Set(regionReportSlugsInCurrentPeriod);

        return getReportSetsByRegionId(reportSet.regionId)
            .filter((reportSet) => regionReportSlugsToShowSet.has(reportSet.slug))
            .map((reportSet): ReportTableProps['reportSetLinks'][number] => ({
                reportSet,
                hasReportForCurrentPeriod: regionReportSlugsInCurrentPeriodSet.has(reportSet.slug),
            }));
    }, [reportSet.regionId, regionReportSlugsToShow, regionReportSlugsInCurrentPeriod]);

    // shared amongst all ReportTable components
    const reportTableProps: ReportTableProps = {
        accessKey,
        reportSet,
        reportSetLinks,
        reportPeriods,
        period,
        periodStartDateString,
        periodEndDateString,
    };

    const pageTitle = `${regionTitle} - ${reportSetTitle} - ${reportPeriod} - ${intl.formatMessage(siteTitle)}`;

    return (
        <>
            <Head>
                <title>{pageTitle}</title>
            </Head>
            {accessKey ? (
                <div
                    css={{
                        transform: 'rotate(45deg)',
                        right: -90,
                        width: 300,
                        top: 34,
                        color: 'white',
                        fontWeight: 'bold',
                        padding: '1em 0',
                        position: 'fixed',
                        background: 'rgba(255, 0, 0, 0.9)',
                        textAlign: 'center',
                        zIndex: 1000,
                    }}
                >
                    Embargo preview
                </div>
            ) : null}
            <Container>
                <Stack>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            minHeight: 'calc(100vh - 30px)', // show some extra content to hint at scrolling
                            pt: 2,
                            [theme.breakpoints.down('md')]: {
                                minHeight: 'calc(100vh)',
                            },
                        }}
                        direction="column"
                    >
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Link href="/">
                                        <TritonLogo
                                            css={{
                                                [theme.breakpoints.only('xs')]: {
                                                    width: '100%',
                                                    maxWidth: 100,
                                                    verticalAlign: 'middle',
                                                },
                                            }}
                                        />
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <NavLinks />
                                </Grid>
                            </Grid>
                        </Grid>
                        {fn(() => {
                            switch (reportType) {
                                case 'networkAu': {
                                    return <ReportTableNetworkAu.Component {...reportTableProps} />;
                                }

                                case 'networkCa': {
                                    return <ReportTableNetworkCa.Component {...reportTableProps} />;
                                }

                                case 'networkNl': {
                                    return <ReportTableNetworkNl.Component {...reportTableProps} />;
                                }

                                case 'networkNz': {
                                    return <ReportTableNetworkNz.Component {...reportTableProps} />;
                                }

                                case 'networkUsDownloads': {
                                    return <ReportTableNetworkUsDownloads.Component {...reportTableProps} />;
                                }

                                case 'networkUsUsers': {
                                    return <ReportTableNetworkUsUsers.Component {...reportTableProps} />;
                                }

                                case 'podcastAu': {
                                    return <ReportTablePodcastAu.Component {...reportTableProps} />;
                                }

                                case 'podcastLatam': {
                                    return <ReportTablePodcastLatam.Component {...reportTableProps} />;
                                }

                                case 'networkLatam': {
                                    return <ReportTableNetworkLatam.Component {...reportTableProps} />;
                                }

                                case 'podcastNl': {
                                    return <ReportTablePodcastNl.Component {...reportTableProps} />;
                                }

                                case 'podcastNz': {
                                    return <ReportTablePodcastNz.Component {...reportTableProps} />;
                                }

                                case 'podcastUs': {
                                    return <ReportTablePodcastUs.Component {...reportTableProps} />;
                                }

                                case 'salesRepresentativeAu': {
                                    return <ReportTableSalesRepresentativeAu.Component {...reportTableProps} />;
                                }

                                case 'demosPlusPodcastAu': {
                                    return <ReportTableDemosPlusPodcastAu.Component {...reportTableProps} />;
                                }
                            }
                        })}
                    </Grid>
                    <Box id="disclaimer">
                        <TritonDivider />
                        <Typography
                            component="div"
                            pb={6}
                            css={{
                                fontSize: 14,
                                color: alpha(theme.tritonColors.darkBlue, 0.6),
                                '& ul': {
                                    paddingInlineStart: 20,
                                },
                                '& a': {
                                    textDecoration: 'underline',
                                    '&:hover': {
                                        color: theme.tritonColors.darkBlue,
                                    },
                                },
                            }}
                        >
                            <ReportDisclaimer regionId={reportSet.regionId} period={period} locale={locale} />
                        </Typography>
                    </Box>
                </Stack>
            </Container>
            <TritonFooter />
        </>
    );
};

export default Ranker;

export const getServerSideProps: GetServerSideProps<PageProps> = async (context) => {
    const { query } = context;
    const slug = query['slug'];

    if (!Array.isArray(slug) || slug.length > 4) {
        addCacheControlHeader(context.res, cacheDurationSeconds.report.error);

        return {
            notFound: true,
        };
    }

    const result = await getReportPeriods({
        regionId: slug[0],
        reportSetSlug: slug[1],
        year: slug[2],
        month: slug[3],
        now: new Date(),
        accessKey: getFirstQueryParam(query['accessKey']) ?? null,
    });

    switch (result.type) {
        case 'ok': {
            addCacheControlHeader(context.res, cacheDurationSeconds.report.success);

            return {
                props: result.props,
            };
        }

        case 'notFound': {
            addCacheControlHeader(context.res, cacheDurationSeconds.report.error);

            return {
                notFound: true,
            };
        }

        case 'redirect': {
            addCacheControlHeader(context.res, cacheDurationSeconds.report.success);

            return {
                redirect: {
                    destination: result.destination,
                    permanent: false,
                },
            };
        }
    }
};

async function getReportPeriods({
    regionId: regionIdUnvalidated,
    reportSetSlug: reportSetSlugUnvalidated,
    year: yearUnvalidated,
    month: monthUnvalidated,
    now,
    accessKey,
}: {
    regionId: string | undefined;
    reportSetSlug: string | undefined;
    year: string | undefined;
    month: string | undefined;
    now: Date;
    accessKey: string | null;
}): Promise<{ type: 'ok'; props: PageProps } | { type: 'notFound' } | { type: 'redirect'; destination: string }> {
    const regionId = getRegionId(regionIdUnvalidated);

    if (!regionId) {
        return {
            type: 'notFound',
        };
    }

    const reportSet = reportSetSlugUnvalidated
        ? getReportSetBySlug(regionId, reportSetSlugUnvalidated)
        : getDefaultReportSet(regionId);

    if (!reportSet) {
        return {
            type: 'notFound',
        };
    }

    const period = getReportPeriodFromStrings({
        year: yearUnvalidated,
        month: monthUnvalidated,
    });

    const [reportsForCurrentRegion, reportsForCurrentRegionAndPeriod, reportsForReportSet] = await Promise.all([
        prisma.report.groupBy({
            by: ['slug'],
            where: {
                OR: [
                    {
                        availableFrom: {
                            lte: now,
                        },
                    },
                    {
                        availableFrom: null,
                    },
                ],
                regionId: reportSet.regionId,
            },
        }),
        period
            ? prisma.report.groupBy({
                  by: ['slug'],
                  where: {
                      OR: [
                          {
                              availableFrom: {
                                  lte: now,
                              },
                          },
                          {
                              availableFrom: null,
                          },
                      ],
                      regionId: reportSet.regionId,
                      period: getDateUtcFromReportPeriod(period),
                  },
              })
            : null,
        prisma.report.findMany({
            where: {
                OR: [
                    {
                        availableFrom: {
                            lte: now,
                        },
                    },
                    {
                        availableFrom: null,
                    },
                    {
                        accessKey: accessKey ?? undefined,
                    },
                ],
                regionId: reportSet.regionId,
                slug: reportSet.slug,
            },
            select: {
                period: true,
                periodEnd: true,
                periodStart: true,
            },
            orderBy: {
                period: 'desc',
            },
        }),
    ]);

    if (reportsForReportSet.length === 0) {
        return {
            type: 'notFound',
        };
    }

    if (!period) {
        // if the year or month are invalid, redirect to the latest report
        const period = getReportPeriodFromDate(reportsForReportSet[0].period);

        return {
            type: 'redirect',
            destination: getRankerUrl({
                regionId: reportSet.regionId,
                slug: reportSet.slug,
                period,
            }),
        };
    }

    const regionReportSlugsToShow = reportsForCurrentRegion.map((report) => report.slug);

    const regionReportSlugsInCurrentPeriod = reportsForCurrentRegionAndPeriod?.map((report) => report.slug) ?? [];

    const reportPeriods = reportsForReportSet.map((report) => getReportPeriodFromDate(report.period));

    const currentPeriodReport = reportsForReportSet.find((report) =>
        reportPeriodsAreEqual(getReportPeriodFromDate(report.period), period),
    );

    if (!currentPeriodReport) {
        return {
            type: 'notFound',
        };
    }

    const periodStartDateString = getDateStringFromISO8601String(currentPeriodReport.periodStart.toISOString());
    const periodEndDateString = getDateStringFromISO8601String(currentPeriodReport.periodEnd.toISOString());

    return {
        type: 'ok',
        props: {
            regionReportSlugsToShow,
            regionReportSlugsInCurrentPeriod,
            reportSet,
            period,
            periodStartDateString,
            periodEndDateString,
            accessKey,
            reportPeriods,
        },
    };
}
